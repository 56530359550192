import {Button} from "baseui/button";
import {useStyletron} from "baseui";
import {forwardRef} from "react";


const BorderedButton = forwardRef((props, ref) => {
    const [css, theme] = useStyletron();  // eslint-disable-line

    const {overrides={}, ...restProps} = props;
    const $style = {
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '7px',
        paddingBottom: '7px',
        borderTopRightRadius: theme.borders.radius200,
        borderTopLeftRadius: theme.borders.radius200,
        borderBottomRightRadius: theme.borders.radius200,
        borderBottomLeftRadius: theme.borders.radius200,
        borderTopStyle: theme.borders.border200.borderStyle,
        borderBottomStyle: theme.borders.border200.borderStyle,
        borderLeftStyle: theme.borders.border200.borderStyle,
        borderRightStyle: theme.borders.border200.borderStyle,
        borderTopWidth: theme.borders.border200.borderWidth,
        borderLeftWidth: theme.borders.border200.borderWidth,
        borderRightWidth: theme.borders.border200.borderWidth,
        borderBottomWidth: theme.borders.border200.borderWidth,
        borderTopColor: theme.borders.border200.borderColor,
        borderBottomColor: theme.borders.border200.borderColor,
        borderLeftColor: theme.borders.border200.borderColor,
        borderRightColor: theme.borders.border200.borderColor,
    };
    overrides.BaseButton = overrides.BaseButton || {};
    let BaseButtonOverride = overrides.BaseButton;

    if (BaseButtonOverride.hasOwnProperty("style")) {
        switch (typeof BaseButtonOverride.style) {
            case "function":
                overrides.BaseButton.style = {...$style, ...BaseButtonOverride.style({$theme: theme})}
                break;
            case "object":
                overrides.BaseButton.style = {...$style, ...BaseButtonOverride.style}
                break;
            default:
                break;
        }
    } else {
        overrides.BaseButton.style = $style;
    }

    return <Button overrides={overrides} {...restProps} ref={ref} />
})
export default BorderedButton;

// BorderedButton.defaultProps = {overrides: {BaseButton: {}}}